import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import { Button, TextField } from '@mui/material';

export default function ManageSub(props) {
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState(undefined);
  const [verified, setVerified] = React.useState(undefined);
  const [subDetails, setSubDetails] = React.useState(undefined);
  const defaultTheme = createTheme({ palette: "light" });

  React.useEffect(() => {
    const getSubData = async () => {
      fetch("https://server.nepeto.com/marter_subscription_details/" + email + "/", {
        mode: "cors",
      }).then((response) => response.json()).then((data) => {setSubDetails(data)});
    };
    if (verified === true) {
      getSubData();
    }
  }, [verified])

  async function sha256(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  const sendVerif = async () => {
    setVerified(false)
    fetch("https://server.nepeto.com/marter_verify_user/" + email + "/", {
      mode: "cors",
    }).then((response) => response.json()).then((data) => { setCode(data) });
  };

  const cancelSub = async () => {
    fetch("https://server.nepeto.com/marter_cancel_sub/" + email + "/", {
      mode: "cors",
    }).then((response) => response.json()).then((data) => { window.location.href="/" });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={'light'} setEmailModal={props.setEmailModal} />
      <Box>
        <h1 style={{ paddingTop: "15vh" }}>Manage Subscription</h1>
        {verified !== true ? <>
          <h2>Enter your email address:</h2>
          <TextField
            id="outlined-basic"
            hiddenLabel
            size="small"
            variant="outlined"
            aria-label="Enter your email address"
            placeholder="Your email address"
            name="email"
            inputProps={{
              autocomplete: 'off',
              ariaLabel: 'Enter your email address',
            }}
            onChange={(e) => setEmail(e.target.value)}
          /><br /><br />
          <Button variant="contained" style={{ backgroundColor: "rgb(230,175,0)" }} onClick={(e) => {  e.target.disabled = true; e.target.style.backgroundColor = "gray"; sendVerif(); }}>Send verification code</Button>
          <br />
          <br />
          <br />
          {verified !== undefined && <>
            <h2>Enter the verification code:</h2>
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter Verification Code"
              placeholder="Enter Verification Code"
              name="email"
              inputProps={{
                autocomplete: 'off',
                ariaLabel: 'Enter Verification Code',
              }}
              onChange={(e) => sha256(e.target.value).then((v) => { if (v === code) { setVerified(true) } })}
            /></>}
        </> :
          <>
            {subDetails && (subDetails['active'] ? "Subscription is active" : "Subscription is not active")}<br/>
            {subDetails && subDetails['active'] && subDetails['next_billing_time'] && `Next billing: ${new Date(subDetails['next_billing_time']).toLocaleString()}`}<br/><br/>
            {subDetails && subDetails['active'] && <Button variant="contained" style={{ backgroundColor: "darkred", fontSize: "75%" }} onClick={(e) => { e.target.disabled = true; e.target.style.backgroundColor = "gray"; e.target.innerHTML = "Loading..."; cancelSub();}}>Cancel Subscription</Button>}
          </>}
      </Box>
    </ThemeProvider>
  );
}

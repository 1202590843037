import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Comprehensive Data Insights',
    description:
      'Get detailed info about how products are doing on Walmart. See sales patterns and price patterns, so you can make smart choices to grow your business.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Easy to Setup',
    description:
      'We have a Chrome extension for easy setup and live data viewing. You can also use our upcoming web app/mobile app.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Great user experience',
    description:
      'Integrate our product into your routine with an intuitive and easy-to-use interface.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Innovative functionality',
    description:
      'Stay ahead with features that set new standards, addressing your evolving needs better than the rest.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Reliable support',
    description:
      'Count on our responsive customer support, offering assistance that goes beyond the purchase.',
  },
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Customize Marter',
    description:
      'We welcome any suggestions. If you desire additional functionality, please send us an email, and we will add it ASAP!',
  },
];

export default function Highlights(props) {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Explore why our product stands out: accuracy, reliable data, user-friendly design, and outstanding informative insights. Get great customer support and precision in every detail.
          </Typography>
        </Box>
        <iframe style={{width: "75%", height: "400px"}} src="https://www.youtube.com/embed/n-j15j38HRE?si=lEa0kB74dkTtLZ5H"
          frameborder="0"
          title="Marter"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        {!props.silentJim && <iframe style={{width: "75%", height: "400px"}} src="https://www.youtube.com/embed/SG-GYBsfGZc?si=6qrTuev0xMEtHgNZ"
          frameborder="0"
          title="Marter"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>}
      </Container>
    </Box>
  );
}
